import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import styles from "./TableData.module.css";
import Filters from "../../components/Filters/Filters";
import JSZip from "jszip";
import DataTable from "datatables.net-react";
import DT from "datatables.net-dt";
import "datatables.net-dt/css/dataTables.dataTables.css";
import "datatables.net-buttons/js/buttons.html5";
import Button from "../Button/Button";
import { tablesColumns } from "../../tablesColumns";
import infoIcon from "../../assets/img/info.svg";
import InfoModal from "../InfoModal/InfoModal";
import { APP_VIEWS } from "../../utils/constants";
import { getEndDay } from "../../utils/utils";

window.JSZip = JSZip;
DataTable.use(DT);

const TableData = ({
  tableData,
  onSubmit,
  setIsOpenAddChat,
  handleDeleteChat,
  handleClickItem,
  profileWa,
  profileTg,
  appView,
  chats,
  employees,
  filteredChats,
  filteredEmployees,
  handleUpdateChatsAndNotifications,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const tableRef = useRef(null);

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const getStatusProfile = (messenger, profileWa, profileTg) => {
    if (!profileWa || !profileTg) {
      return "";
    }
    if (messenger === "whatsapp") {
      return profileWa.authorized ? "" : "не подключен WA";
    } else if (messenger === "telegram") {
      return profileTg.authorized ? "" : "не подключен TG";
    }
    return "";
  };
  const columns = useMemo(() => {
    const table = tablesColumns.find((obj) => obj.title === appView);
    if (table) {
      return table.columns
        ? table.columns.map((column, index) => ({
            data: column.data,
            title: column.title,
            className: column.className,
            width: column.width,
            type: index ? "html-num" : "html",
            render: (data, type, row) => {
              if (type === "display") {
                if (index === 0) {
                  let title = "";
                  if (row.typeItem === "employee") {
                    title = row.id;
                  }
                  return (
                    `<div class="info"  title="${
                      data || title
                    }" onclick="document.dispatchEvent(new CustomEvent('infoClick', { detail: '${
                      row.id
                    }' }))" >
                    <img src="${infoIcon}" />
                    <span class="line-clamp line-clamp--2">${
                      data || title
                    }</span>
                    ${
                      row.deviants.length
                        ? "<span class='warning_icon'>⚠️</span>"
                        : ""
                    }
                    </div>` +
                    `<span class="warning_text">${getStatusProfile(
                      row.messenger,
                      profileWa,
                      profileTg
                    )}</span>`
                  );
                }
                if (column.data === "totalMessages") {
                  return `${data.totalMessages} <span>(${Math.round(
                    data.medianTotalMessages
                  )})</span>`;
                }
                if (data.isLoading) {
                  return "загрузка...";
                }
              }
              if (index && type === "sort") {
                return column.data === "negative"
                  ? data.percentDiff * (data.plusMinus === "+" ? 1 : -1)
                  : data.percent;
              }
              let currentDeviant = row.deviants.find((p) =>
                row.deviants.find((p) => p.property === column.data)
              );

              let className = "";
              let title = "";
              let backgroundCell = "";
              let textPercent = `${data.percent}%`;
              if (currentDeviant) {
                title = `Средний медианный % - ${currentDeviant.first_percent}`;
                className = styles.warningCell;
                backgroundCell = `<div class=${styles.warningBackground}></div>`;
                textPercent = `<p class="${styles.percent}">${data.percent}%</p>`;
              }

              return (
                `${backgroundCell}<div class="${className}" title="${
                  data.value
                } (${data.medianValue ?? 0}) ${title}">
                ${textPercent} <span>(${data.plusMinus}${
                  data.percentDiff
                }%)</span></div>` ?? ""
              );
            },
          }))
        : [];
    }
    return [];
  }, [appView]);
  const options = {
    responsive: true,
    paging: false,
    searching: false,
    info: false,
    ordering: true,
    dom: "Bfrtip",
    buttons: [
      {
        extend: "excelHtml5",
        text: "Скачать в XSLX",
        className: styles.tableExportHidden,
        exportOptions: {
          columns: ":visible",
        },
      },
    ],
    initComplete: function () {
      tableRef.current = this;
    },
  };

  useEffect(() => {
    const handleCustomEvent = (event) => {
      handleClickItem(event.detail);
    };
    document.addEventListener("infoClick", handleCustomEvent);
    return () => {
      document.removeEventListener("infoClick", handleCustomEvent);
    };
  }, [handleClickItem]);

  const handleExportClick = () => {
    const exportButton = document.querySelector(`.${styles.tableExportHidden}`);
    if (exportButton) {
      exportButton.click();
    }
  };
  const viewedButtonUpdate =
    (profileWa.authorized || profileTg.authorized) &&
    handleUpdateChatsAndNotifications &&
    chats.length &&
    chats.some((chat) => {
      const date = new Date(chat.timeLastUpdate);
      const startYesterday = getEndDay(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
      );
      return date.getTime() < startYesterday;
    });

  return (
    <div className={styles.table}>
      <div className={styles.tableOptions}>
        <div className={styles.tableFilters}>
          <Filters
            appView={appView}
            onSubmit={onSubmit}
            chats={chats}
            employees={employees}
          />
        </div>
        <div className={styles.tableExport}>
          {viewedButtonUpdate ? (
            <Button
              text="Запустить обновление"
              type="secondary"
              action={handleUpdateChatsAndNotifications}
            />
          ) : null}
          <Button
            text="Скачать в XSLX"
            type="secondary"
            action={handleExportClick}
          />
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <DataTable
          data={appView === APP_VIEWS.CHATS ? filteredChats : filteredEmployees}
          columns={columns}
          options={options}
        >
          <thead>
            <tr></tr>
          </thead>
        </DataTable>
      </div>
    </div>
  );
};

export default TableData;
