import React, { useCallback, useMemo, useRef } from "react";
import { useState, useEffect } from "react";

import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Navigate } from "react-router";
import mainApi from "./utils/MainApi";
import { Notyf } from "notyf";
import Preloader from "./components/Preloader/Preloader";
import Popup from "./components/Popup/Popup";
import imageLoading from "./assets/img/loading.gif";
import {
  APP_VIEWS,
  MESSENGERS,
  REPORT_VIEWS,
  STATUSES_CHAT,
  STATUS_CONNECT_TG,
  TEXTS_STATUSES,
} from "./utils/constants";
import ConnectingWA from "./components/ConnectForms/ConnectingWA/ConnectingWA";
import ConnectingTG from "./components/ConnectForms/ConnectingTG/ConnectingTG";
import "notyf/notyf.min.css";
import AdminPanel from "./components/Admin/Admin";
import WebSocketComponent from "./components/WebSocket/WebSocket";
import {
  formatDateToShortString,
  getEndDay,
  handleEmployeesBeforeView,
  replaceValuesForMessages,
  replaceValuesForСhats,
} from "./utils/utils";
import MessageList from "./components/MessageList/MessageList";

const initialCurrentUser = {
  email: "",
  name: "",
  favourite_chats_tg: [],
  favourite_chats_wa: [],
  profile_tg: null,
  profile_wa: null,
  _id: "",
};

const App = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();
  const url = location.pathname;
  const notyf = useMemo(() => new Notyf({ duration: 3000 }), []);

  // авторизован клиент или нет
  const [isUserAuthed, setIsUserAuthed] = useState(false);
  // открыт бургер меню или нет
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false);
  // загрузки идет или нет
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  // данные текущего пользователя
  const [currentUser, setСurrentUser] = useState(initialCurrentUser);
  // профили для авторизации в системе
  const [profileTg, setProfileTg] = useState(null);
  const [profileWa, setProfileWa] = useState(null);
  // текст уведомления
  const [notifyError, setNotifyError] = useState("");
  const [notifyInfo, setNotifyInfo] = useState("");
  // процесс авторизации в whatsapp/tg
  const [isConnectingWa, setIsConnectingWa] = useState(false);
  const [qrWa, setQrWa] = useState(null);
  const [connectingTgStatus, setConnectingTG] = useState(
    STATUS_CONNECT_TG.NONE
  );
  // настройка чатов
  const [allChatsWA, setAllChatsWA] = useState([]);
  const [allChatsTG, setAllChatsTG] = useState([]);
  const [topicsTG, setTopicsTG] = useState([]);
  const [isSelectChatsWA, setIsSelectChatsWA] = useState(false);
  const [isSelectChatsTG, setIsSelectChatsTG] = useState(false);

  // все отчеты
  const [chats, setChats] = useState([]);
  const [employees, setEmployes] = useState([]);
  const [employeesFilteredByChats, setEmployeesFilteredByChats] =
    useState(null);
  const chatsRef = useRef(chats);
  const [currentChat, setCurrentChat] = useState(null);
  const employeeRef = useRef(null);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [msgsCurrentReport, setMsgsCurrentReport] = useState([]);
  const [isOpenPopupMessages, setIsOpenPopupMessages] = useState(false);

  // фильтры сообщений
  const [senderFilter, setSenderFilter] = useState("");
  const [chatNameFilter, setChatNameFilter] = useState("");
  const [emotionFilter, setEmotionFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  // вид приложения
  const [appView, setAppView] = useState(APP_VIEWS.CHATS);

  // добавляем чат
  const [isOpenAddChat, setIsOpenAddChat] = useState(false);

  // модалка отчет чат/сотрудник/сообщения
  const [viewReport, setViewReport] = useState(null);

  // ошибки приходят сюда
  const handlerErrors = (err) => {
    let text;
    switch (err.message) {
      case "Failed to fetch":
        text = "Проблемы с интернетом или сервером";
        break;
      case "Validation failed":
        text = "Email указан некорректно";
        break;
      default:
        text = err.message;
    }
    setNotifyError(text);
    console.log(err);
  };
  // функция удаления данных пользователя с приложения
  const removeUserData = (notify = true) => {
    setIsUserAuthed(false);
    localStorage.clear();
    setСurrentUser(initialCurrentUser);
    clearProfiles();
  };
  // функция удаления данных профилей
  const clearProfiles = () => {
    setProfileTg(null);
    setProfileWa(null);
    setConnectingTG(STATUS_CONNECT_TG.NONE);
    setAllChatsTG([]);
    setTopicsTG([]);
    setAllChatsWA([]);
  };

  // проверка данных пользователя на сервере
  const checkUser = async () => {
    try {
      setIsLoading(true);
      const data = await mainApi.onCheckUser();
      if (!data.error) {
        setIsUserAuthed(true);
        setСurrentUser(data);
        navigate(url);
      } else {
        removeUserData(false);
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  // загрузка профилей
  const loadProfiles = async () => {
    try {
      setIsLoading(true);

      const data = await mainApi.loadProfiles();
      if (!data.error) {
        const tg = data?.find((p) => p.profile_id === currentUser?.profile_tg);
        const wa = data?.find((p) => p.profile_id === currentUser?.profile_wa);
        setProfileTg(tg ? { ...tg, platform: MESSENGERS.TG } : null);
        setProfileWa(wa ? { ...wa, platform: MESSENGERS.WA } : null);
        return { tg, wa };
      } else {
        setNotifyError("Не удалось получить профили");
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  // авторизация в WA  получение QR кода
  const getQrWa = async () => {
    try {
      setIsLoading(true);
      const profiles = await loadProfiles();
      if (profiles.wa.authorized) {
        setIsConnectingWa(false);
        setIsLoading(false);
        return;
      }
      const data = await mainApi.getQrWa();
      if (!data.error) {
        setQrWa(data.qrCode);
      } else {
        setNotifyError(
          "Не удалось Загрузить QR код для авторизации в Whatsapp"
        );
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  // авторизация в тг
  const authTG = async (status, content) => {
    try {
      setIsLoading(true);
      let data;
      if (status === STATUS_CONNECT_TG.PHONE) {
        data = await mainApi.sendPhoneTG(currentUser.profile_tg, content);
      }
      if (status === STATUS_CONNECT_TG.CODE) {
        data = await mainApi.sendCodeTG(currentUser.profile_tg, content);
      }
      if (status === STATUS_CONNECT_TG.PASSWORD) {
        data = await mainApi.sendPasswordTG(currentUser.profile_tg, content);
      }
      if (!data?.error) {
        setIsLoading(false);
        return data;
      } else {
        setNotifyError("Не удалось отправить код ");
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  // функция создания пользователя - регистрация
  const handleCreateUser = async ({ email, password, name }) => {
    setIsLoading(true);
    mainApi
      .onRegister(email, password, name)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        checkUser()
          .then(() => {
            navigate("/movies");
          })
          .catch(handlerErrors);
      })
      .catch(handlerErrors)
      .finally(() => setIsLoading(false));
  };

  // функция авторизации пользователя - вход
  const handleLogin = async (email, password) => {
    setIsLoading(true);
    mainApi
      .onLogin({ email, password })
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        checkUser()
          .then(() => {
            navigate("/");
            setNotifyInfo(`Авторизация успешна`);
          })
          .catch(handlerErrors);
      })
      .catch(handlerErrors)
      .finally(() => setIsLoading(false));
  };

  // функция выхода пользователя и удаления куков,а также всех данных
  const handleSignOut = async () => {
    setIsLoading(true);
    mainApi
      .onLogout()
      .then(() => {
        removeUserData();
        setNotifyInfo(`Вы вышли`);
        navigate("/");
      })
      .catch(handlerErrors)
      .finally(() => setIsLoading(false));
  };

  // функция изменения данных пользователя
  const handleEditUser = async ({ name, email }) => {
    setIsLoading(true);
    mainApi
      .onEditProfile({ name, email })
      .then((response) => {
        if (response.error) {
          removeUserData();
          throw new Error(response.error);
        }
        setNotifyError(`Новые имя и емейл сохранены!`);
        setСurrentUser(response);
      })
      .catch(handlerErrors)
      .finally(() => setIsLoading(false));
  };

  // авторизация в мессенжере
  const handleConnect = async (messenger) => {
    if (messenger === MESSENGERS.WA) {
      setIsConnectingWa(true);
      await getQrWa();
    }
    if (messenger === MESSENGERS.TG) {
      setConnectingTG(STATUS_CONNECT_TG.PHONE);
    }
  };

  // обработка отключения от профиля
  const handleDisConnect = async (messenger) => {
    try {
      setIsLoading(true);
      if (messenger === MESSENGERS.WA) {
        await mainApi.logoutMessenger(currentUser?.profile_wa);
      }
      if (messenger === MESSENGERS.TG) {
        await mainApi.logoutMessenger(currentUser?.profile_tg);
      }
      setTimeout(() => clearProfiles(), 3000);
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAllChats = async () => {
    try {
      setIsLoadingChats(true);
      setNotifyInfo("Начата загрузка чатов");
      const promises = [];

      if (profileWa?.authorized) {
        promises.push(mainApi.getAllChats(currentUser?.profile_wa));
      }

      if (profileTg?.authorized) {
        promises.push(mainApi.getAllChats(currentUser?.profile_tg));
      }

      const results = await Promise.all(promises);

      if (profileWa?.authorized) {
        setAllChatsWA(results[0]?.dialogs);
      }

      if (profileTg?.authorized) {
        setAllChatsTG(results[profileWa.authorized ? 1 : 0]?.dialogs);
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoadingChats(false);
      setNotifyInfo("Список чатов загружен");
    }
  };
  const getTopicsTG = async (profile_id, chat_id) => {
    try {
      setIsLoading(true);
      if (profile_id && chat_id) {
        const response = await mainApi.getTopicsTG(profile_id, chat_id);
        if (response) setTopicsTG(response.topics);
      }
      return { topics: [] };
    } catch (error) {
      handlerErrors(error);
      setIsLoading(false);
      return { topics: [] };
    } finally {
      setIsLoading(false);
    }
  };
  const loadEmployes = async () => {
    try {
      const employees = await mainApi.getEmployes([]);
      if (employees.error) {
        setNotifyError(employees.error);
        return;
      }
      // если чат еще обрабатывается, то у его сотрудников тоже надо показать текст "идет загрузка"
      const result = handleEmployeesBeforeView(chatsRef.current, employees);
      setEmployes(result);
    } catch (error) {
      handlerErrors(error);
    }
  };
  const loadEmployesFilteredByChats = async (arrayOfChats) => {
    try {
      const filteredEmployeesByChats = await mainApi.getEmployes(arrayOfChats);

      if (filteredEmployeesByChats.error) {
        setNotifyError(filteredEmployeesByChats.error);
        return;
      }
      const result = handleEmployeesBeforeView(
        chatsRef.current,
        filteredEmployeesByChats
      );
      setEmployeesFilteredByChats(result);
    } catch (error) {
      handlerErrors(error);
    }
  };

  const getChats = async () => {
    try {
      const chats = await mainApi.getChats();
      if (chats.error) {
        setNotifyError(chats.error);
        return;
      }
      setChats(replaceValuesForСhats(chats, APP_VIEWS.CHATS));
    } catch (error) {
      handlerErrors(error);
    }
  };
  const getReport = async (id) => {
    try {
      setIsLoading(true);
      const chat = await mainApi.getReport(id);
      if (!chat.message) {
        setCurrentChat(chat);
      } else {
        setNotifyError("Не удалось загрузить чат");
        setCurrentChat({
          status_order: TEXTS_STATUSES.error,
          _id: id,
          ...chat,
        });
      }
    } catch (error) {
      setCurrentChat({ status_order: TEXTS_STATUSES.error, _id: id });
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickItemChat = async (id) => {
    setCurrentChat(null);
    try {
      setIsLoading(true);
      const chat = await mainApi.getChat(id);
      if (chat._id) {
        setCurrentChat(chat);
        setViewReport(
          appView === APP_VIEWS.CHATS
            ? REPORT_VIEWS.CHAT
            : REPORT_VIEWS.EMPLOYEE
        );
      } else {
        if (chat.error) {
          setNotifyError(chat.error);
        } else {
          setNotifyError("Не удалось загрузить чат");
        }
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickItemEmployee = async (id, arrayOfChats) => {
    setCurrentEmployee(null);
    try {
      setIsLoading(true);
      const employee = await mainApi.getEmployee(id, arrayOfChats);
      if (employee.id) {
        setCurrentEmployee(employee);
        setViewReport(
          appView === APP_VIEWS.CHATS
            ? REPORT_VIEWS.CHAT
            : REPORT_VIEWS.EMPLOYEE
        );
      } else {
        if (employee.error) {
          setNotifyError(employee.error);
        } else {
          setNotifyError("Не удалось загрузить данные по сотруднику");
        }
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteChat = async (id) => {
    try {
      setIsLoading(true);
      const chat = await mainApi.delByChat(id);
      if (chat._id) {
        const filteredChats = chats.filter((r) => r.id !== chat._id);
        setChats(filteredChats);
        setCurrentChat(null);
        setViewReport(null);
        setNotifyInfo(`чат <b>${chat.name}</b> удален`);
      } else {
        if (chat.error) {
          setNotifyError(chat.error);
        } else {
          setNotifyError("Не удалось удалить чат");
        }
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChats = async (messenger) => {
    try {
      if (messenger === MESSENGERS.WA) {
        setIsSelectChatsWA(true);
      }
      if (messenger === MESSENGERS.TG) {
        setIsSelectChatsTG(true);
      }
    } catch (error) {
      handlerErrors(error);
    }
  };
  const handleLoadChats = async (profile_id) => {
    try {
      setIsLoading(true);
      let res;
      if (profile_id === currentUser?.profile_wa) {
        res = await mainApi.getAllChats(currentUser?.profile_wa);
        if (res.status === "done") {
          setAllChatsWA(res.dialogs);
        }
      }
      if (profile_id === currentUser?.profile_tg) {
        res = await mainApi.getAllChats(currentUser?.profile_tg);
        if (res.status === "done") {
          setAllChatsTG(res.dialogs);
        }
      }
      if (!res || !res.dialogs) {
        setNotifyError("Ошибка загрузки чатов. Попробуйте чуть позже");
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdateStatusOrder = (msg) => {
    if (msg?.type === "status_chat") {
      const chat = chatsRef.current.find((r) => r.id === msg.content._id);
      if (chat) {
        if (msg.content.status_chat === STATUSES_CHAT.COMPLETED) {
          getChats();
          loadEmployes();
          setNotifyInfo(`Данные по чату <b>${chat.name}</b> обновлены`);
        }
      }
    }
    if (msg?.type === "status_report_employee") {
      if (msg.content?.senderNickname === employeeRef.current?.senderNickname) {
        setCurrentEmployee({
          ...employeeRef.current,
          report: msg.content.report,
        });
      }
    }
  };

  const handleLoadMessagesCurrentReport = async (id) => {
    if (msgsCurrentReport.length) {
      setIsOpenPopupMessages(true);
      return;
    }
    try {
      setIsLoading(true);
      const messages = await mainApi.getMessages(id);
      if (messages) {
        setMsgsCurrentReport(replaceValuesForMessages(messages));
        setIsOpenPopupMessages(true);
      } else {
        if (messages.error) {
          setNotifyError(messages.error);
        } else {
          setNotifyError("Не удалось загрузить сообщения");
        }
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSetMessagesCurrentReportForEmployee = (id) => {
    if (msgsCurrentReport.length) {
      setIsOpenPopupMessages(true);
      return;
    }
    try {
      if (currentEmployee.messages) {
        setMsgsCurrentReport(
          replaceValuesForMessages(currentEmployee.messages)
        );
        setIsOpenPopupMessages(true);
      }
    } catch (error) {
      handlerErrors(error);
    }
  };
  const handleUpdateChatsAndNotifications = async () => {
    try {
      const result = await mainApi.updateChats();
      if (result.message) {
        setNotifyInfo(result.message);
      }
    } catch (error) {
      handlerErrors(error);
    }
  };

  const onClickButtonMessages = (id) => {
    if (appView === APP_VIEWS.CHATS) {
      handleLoadMessagesCurrentReport(id);
    } else if (appView === APP_VIEWS.EMPLOYEES) {
      handleSetMessagesCurrentReportForEmployee();
    }
  };
  const getLogs = async () => {
    mainApi.getLogs().catch(handlerErrors);
  };

  const handleAddChat = async (chat) => {
    try {
      setIsLoading(true);
      const addedChat = await mainApi.createChat({
        profile_id: chat.profile_id,
        chat_id: chat.value,
        name: chat.label,
        topic_id: chat.topic_id,
        topic_name: chat.topic_name,
      });
      if (addedChat.error) {
        setNotifyError(addedChat.error);
        return;
      } else if (addedChat.message) {
        setNotifyInfo(`Чат <b>${chat.label}</b> добавлен`);
        setChats((chats) => [
          ...chats,
          replaceValuesForСhats([addedChat.chat], APP_VIEWS.CHATS, true)[0],
        ]);
      }
    } catch (error) {
      handlerErrors(error);
    } finally {
      setIsLoading(false);
    }
    // добавить в общий список
  };
  //при первой загрузке, если клиент не авторизован проверяем его данные на сервере
  useEffect(() => {
    if (!isUserAuthed) {
      try {
        setIsLoading(true);
        checkUser();
      } catch (error) {
        handlerErrors(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);
  // useEffect(() => {
  //   console.log(msgsCurrentReport);
  // }, [msgsCurrentReport]);

  // загружаем данные профилей
  useEffect(() => {
    if (isUserAuthed && !profileTg && !profileWa) {
      try {
        setIsLoading(true);
        loadProfiles();
      } catch (error) {
        handlerErrors(error);
      } finally {
        setIsLoading(false);
        getChats();
      }
    }
  }, [isUserAuthed, profileTg, profileWa]);

  // если мы добавили текст в уведомления, то открываем модалку
  useEffect(() => {
    if (notifyError) notyf.error?.(notifyError);
    setNotifyError("");
  }, [notifyError, notyf]);

  useEffect(() => {
    if (notifyInfo) notyf.success?.(notifyInfo);
    setNotifyInfo("");
  }, [notifyInfo, notyf]);

  // возвращаем на страницу, куда было обращение
  useEffect(() => {
    if (isUserAuthed) {
      navigate(url);
    }
  }, [isUserAuthed]);

  // если добавляем чат, то надо сначала загрузить весь список
  useEffect(() => {
    if (
      isOpenAddChat &&
      (profileTg || profileWa) &&
      allChatsWA &&
      allChatsTG &&
      [...allChatsWA, ...allChatsTG].length === 0
    ) {
      loadAllChats();
    }
  }, [isOpenAddChat]);

  useEffect(() => {
    if (appView === APP_VIEWS.EMPLOYEES) {
      loadEmployes();
    }
  }, [appView]);

  useEffect(() => {
    chatsRef.current = chats;
  }, [chats]);
  useEffect(() => {
    employeeRef.current = currentEmployee;
  }, [currentEmployee]);

  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/admin"
          element={
            <ProtectedRoute
              isUserAuthed={currentUser.is_admin}
              element={
                <AdminPanel
                  handlerErrors={handlerErrors}
                  setIsLoading={setIsLoading}
                  setNotifyInfo={setNotifyInfo}
                  setNotifyError={setNotifyError}
                  handleSignOut={handleSignOut}
                  getLogs={getLogs}
                />
              }
            />
          }
        />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route
          path="/"
          element={
            <ProtectedRoute
              isUserAuthed={isUserAuthed}
              element={
                <Dashboard
                  currentUser={currentUser}
                  onLogout={handleSignOut}
                  profileTg={profileTg}
                  profileWa={profileWa}
                  handleConnect={handleConnect}
                  handleDisConnect={handleDisConnect}
                  handleSelectChats={handleSelectChats}
                  allChatsWA={allChatsWA ?? []}
                  allChatsTG={allChatsTG ?? []}
                  loadAllChats={loadAllChats}
                  getTopicsTG={getTopicsTG}
                  topicsTG={topicsTG}
                  chats={chats}
                  employees={employees}
                  getReport={getReport}
                  currentChat={currentChat}
                  currentEmployee={currentEmployee}
                  onCloseReport={() => {
                    setCurrentChat(null);
                    setCurrentEmployee(null);
                    setMsgsCurrentReport([]);
                    setViewReport(null);
                  }}
                  onClickButtonMessages={onClickButtonMessages}
                  isLoadingChats={isLoadingChats}
                  appView={appView}
                  setAppView={setAppView}
                  setIsOpenAddChat={setIsOpenAddChat}
                  isOpenAddChat={isOpenAddChat}
                  handleAddChat={handleAddChat}
                  handleDeleteChat={handleDeleteChat}
                  handleClickItemChat={handleClickItemChat}
                  handleClickItemEmployee={handleClickItemEmployee}
                  setViewReport={setViewReport}
                  viewReport={viewReport}
                  messages={msgsCurrentReport ?? []}
                  senderFilter={senderFilter}
                  chatNameFilter={chatNameFilter}
                  emotionFilter={emotionFilter}
                  typeFilter={typeFilter}
                  setSenderFilter={setSenderFilter}
                  setChatNameFilter={setChatNameFilter}
                  setEmotionFilter={setEmotionFilter}
                  setTypeFilter={setTypeFilter}
                  loadEmployesFilteredByChats={loadEmployesFilteredByChats}
                  setEmployeesFilteredByChats={setEmployeesFilteredByChats}
                  employeesFilteredByChats={employeesFilteredByChats}
                  handleUpdateChatsAndNotifications={
                    handleUpdateChatsAndNotifications
                  }
                />
              }
            />
          }
        />
      </Routes>
      <ConnectingWA
        isOpen={isConnectingWa}
        setIsConnectingWa={setIsConnectingWa}
        qrWa={qrWa ?? imageLoading}
        clearProfiles={clearProfiles}
      />
      <ConnectingTG
        setConnectingTG={setConnectingTG}
        connectingTgStatus={connectingTgStatus}
        clearProfiles={clearProfiles}
        sendPhoneTG={(content) => authTG(STATUS_CONNECT_TG.PHONE, content)}
        sendCodeTG={(content) => authTG(STATUS_CONNECT_TG.CODE, content)}
        sendPasswordTG={(content) =>
          authTG(STATUS_CONNECT_TG.PASSWORD, content)
        }
        setNotifyError={setNotifyError}
        setIsLoading={setIsLoading}
      />
      {isLoading && <Preloader />}
      {currentUser._id && (
        <WebSocketComponent
          id={currentUser._id}
          handleUpdateStatusOrder={handleUpdateStatusOrder}
        />
      )}
    </AuthProvider>
  );
};

const ProtectedRoute = ({ element, isUserAuthed }) => {
  return isUserAuthed ? element : <Navigate to="/login" />;
};

export default App;
