import React from "react";
import Box from "../Box/Box";
import Button from "../Button/Button";
import { MESSENGERS } from "../../utils/constants";
import styles from "./StatusProfile.module.css";
import AddChat from "../AddChat/AddChat";

const TEXT_OK = "Все отлично, подключение активно";
const TEXT_DISCONNECTED = "Нажмите кнопку для авторизации";
const TEXT_ERROR = "Для подключения обратитесь к администратору";
const StatusProfile = ({
  handleConnect,
  handleDisConnect,
  profileWa,
  profileTg,
  handleSelectChats,
  isLoadingChats,
  setIsOpenAddChat,
  isOpenAddChat,
  allChatsTG,
  allChatsWA,
  handleAddChat,
  chats,
  loadAllChats,
  getTopicsTG,
  topicsTG,
}) => {
  const profiles = [];
  const connectButton = (messenger, isConnect) => ({
    text: isConnect ? "Подключиться" : "Отключиться",
    type: isConnect ? "primary" : "secondary",
    selector: "button",
    action: () =>
      isConnect ? handleConnect(messenger) : handleDisConnect(messenger),
  });

  const selectChatsButton = (messenger) => ({
    text: isLoadingChats ? "Загрузка чатов..." : "Избранные чаты",
    type: "secondary",
    selector: "button",
    action: () => handleSelectChats(messenger),
  });

  if (profileWa) {
    profiles.push({
      title: `Whatsapp ${profileWa.phone ?? ""}`,
      color: profileWa.authorized ? "#90BE6D" : "#f94144",
      text: profileWa.authorized
        ? TEXT_OK
        : profileWa.paid
        ? TEXT_DISCONNECTED
        : TEXT_ERROR,
      children: profileWa.paid && (
        <div className={styles.buttons}>
          <Button {...connectButton(MESSENGERS.WA, !profileWa.authorized)} />
        </div>
      ),
    });
  }
  if (profileTg) {
    profiles.push({
      title: `Telegram ${profileTg.phone ?? ""}`,
      color: profileTg.authorized ? "#90BE6D" : "#f94144",
      text: profileTg.authorized
        ? TEXT_OK
        : profileTg.paid
        ? TEXT_DISCONNECTED
        : TEXT_ERROR,
      children: profileTg.paid && (
        <div className={styles.buttons}>
          <Button {...connectButton(MESSENGERS.TG, !profileTg.authorized)} />
        </div>
      ),
    });
  }
  profiles.push({
    color: "#af56d9",
    className: styles.selectChats,
    title: "Добавление нового чата",
    text: "Загрузка списка всех чатов с подключенных аккаунтов и добавление в список отслеживаемых чатов",
    children: isOpenAddChat ? (
      <AddChat
        setIsOpenAddChat={setIsOpenAddChat}
        allChatsTG={allChatsTG}
        allChatsWA={allChatsWA}
        isLoadingChats={isLoadingChats}
        profileTg={profileTg}
        profileWa={profileWa}
        handleAddChat={handleAddChat}
        chats={chats}
        loadAllChats={loadAllChats}
        getTopicsTG={getTopicsTG}
        topicsTG={topicsTG}
      />
    ) : (
      <Button
        text="Добавить чат"
        type="secondary"
        action={() => setIsOpenAddChat(true)}
        className="addChatButton"
        disabled={!profileTg.authorized && !profileWa.authorized}
      />
    ),
  });

  return (
    <>
      <Box
        type={"GridTexts"}
        caption={"Статус подключения"}
        content={profiles}
      ></Box>{" "}
    </>
  );
};

export default StatusProfile;
