import React, { useState } from "react";
import Button from "../Button/Button";
import styles from "./AddChat.module.css";
import Select from "react-select";
import {
  selectTheming,
  DropdownIndicator,
  ClearIndicator,
} from "../../utils/CSelect";
const AddChat = ({
  setIsOpenAddChat,
  allChatsWA,
  allChatsTG,
  isLoadingChats,
  profileWa,
  profileTg,
  handleAddChat,
  chats,
  loadAllChats,
  getTopicsTG,
  topicsTG,
}) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const handleChatChange = (selectedOption) => {
    if (!selectedOption) return;
    setSelectedChat(selectedOption);
    if (profileTg?.profile_id === selectedOption.profile_id) {
      getTopicsTG(selectedOption.profile_id, selectedOption.value);
    }
  };
  const handleTopicChange = (selectedOption) => {
    if (!selectedOption) return;
    setSelectedTopic(selectedOption);
    if (selectedOption.value !== null) {
      setSelectedChat({
        ...selectedChat,
        topic_id: selectedOption.value,
        topic_name: selectedOption.label,
      });
    }
  };
  const chatsIds = new Set(chats.map((c) => c.chat_id));

  return (
    <>
      <div className={styles.addChat}></div>
      <div className={styles.addChat} style={{ gap: "6px" }}>
        <Select
          {...selectTheming(false)}
          components={{ DropdownIndicator, ClearIndicator }}
          value={selectedChat}
          onChange={handleChatChange}
          options={[
            {
              label: "Whatsapp",
              options: [
                ...allChatsWA
                  .filter((c) => c.name && !chatsIds.has(c.id))
                  .sort((a, b) => b.last_timestamp - a.last_timestamp)
                  .map((c, i) => ({
                    value: c.id,
                    label: c.name,
                    profile_id: profileWa.profile_id,
                    key: c.id + c.name + i + "wa",
                  })),
              ],
            },
            {
              label: "Telegram",
              options: [
                ...allChatsTG
                  .filter((c) => c.name && !chatsIds.has(c.id))
                  .sort((a, b) => b.last_timestamp - a.last_timestamp)
                  .map((c, i) => ({
                    value: c.id,
                    label: c.name,
                    profile_id: profileTg.profile_id,
                    key: c.id + c.name + i + "tg",
                  })),
              ],
            },
          ]}
          noOptionsMessage={() => "Результатов не найдено"}
          placeholder={isLoadingChats ? "Загрузка чатов..." : "Выберите чат"}
          className={styles.selectChat}
        />
        {topicsTG.length ? (
          <Select
            {...selectTheming(false)}
            components={{ DropdownIndicator, ClearIndicator }}
            value={selectedTopic ?? { value: null, label: "Все треды" }}
            onChange={handleTopicChange}
            options={[
              {
                label: "Треды",
                options: [
                  {
                    value: null,
                    label: "Все треды",
                    profile_id: profileTg.profile_id,
                    key: "all",
                  },
                  ...topicsTG
                    .sort(
                      (a, b) =>
                        new Date(b.date).getTime() - new Date(a.date).getTime()
                    )
                    .map((c, i) => ({
                      value: c.id,
                      label: c.title,
                      profile_id: profileTg.profile_id,
                      key: c.id + c.title + i + "tg",
                    })),
                ],
              },
            ]}
            noOptionsMessage={() => "Результатов не найдено"}
            // isClearable
            placeholder={
              isLoadingChats ? "Загрузка тредов..." : "Выберите тред"
            }
            className={styles.selectChat}
          />
        ) : null}
        <Button
          text="Добавить"
          type={selectedChat ? "primary" : "secondary"}
          disabled={!selectedChat && (topicsTG.length ? !selectedTopic : true)}
          action={() => {
            handleAddChat(selectedChat);
            setIsOpenAddChat(false);
          }}
        />
        <Button
          text="Обновить"
          disabled={isLoadingChats}
          type={"secondary"}
          action={() => {
            loadAllChats();
          }}
        />
        <Button
          text="Отмена"
          type="secondary"
          action={() => setIsOpenAddChat(false)}
        />
      </div>
    </>
  );
};

export default AddChat;
